import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Mosaic Non-Profit Development', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'After an amazing 10 years, Mosaic has ceased operations.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Thank you for an amazing',
  name: '10 years',
  subtitle: 'Mosaic has ceased operations.',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `I started Mosaic Non-Profit Development in 2010. My primary goal was to equip organizations with the tools they needed to be wildly successful in their fundraising. `,
  paragraphTwo: `As of 2020, I'm honored to share that we achieved that goal. Mosaic helped raise over $500MM for non-profits all over the world. `,
  paragraphThree: `I extend my sincere gratitude to all of you who made it possible.  `,
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Inversant, Inc.',
    info: `I've been appointed as a senior executive for a cause I'm deeply passionate about. `,
    info2: `Inversant is a Boston based non-profit focused on making higher education attainable by everyone. I welcome you to connect with the organization on the web and across social media.`,
    url: 'https://inversant.org',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Have a question or just want to say hello?',
  btn: 'Email Me',
  email: 'mailto:hhancock@mosaicnpd.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/heidihancock/',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/heidi.hancockcfre',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://instagram.com/heidhanc',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
